<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("message.tableHeader.name") }}</label>
          <b-form-input
            autofocus
            v-model.trim="filters.pname"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Facility</label>
          <v-select
            v-model="filters.fid"
            class="w-100"
            :options="facilityOptions"
            :reduce="(value) => value.id"
            label="name"
            multiple
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>OMA</label>
          <v-select
            v-model="filters.oid"
            class="w-100"
            :options="omaOptions"
            :reduce="(value) => value.id"
            label="name"
            @keyup.enter="searchFilter"
            @search="searchOma"
          >
            <template slot="option" slot-scope="option">
              <SelectUser :option="option" />
            </template>
            <template slot="selected-option" slot-scope="option">
              <SelectUser :option="option" />
            </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Status") }}</label>
          <v-select
            v-model="filters.status"
            class="w-100"
            :options="statusOptions"
            :reduce="(value) => value.value"
            label="name"
            @keyup.enter="searchFilter"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.Start Date") }}</label>
          <flat-pickr
            v-model="filters.srdate.start"
            :config="fConfig"
            class="form-control"
            placeholder="MM-DD-YYYY"
            type="date"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>{{ $t("Form.End Date") }}</label>
          <flat-pickr
            v-model="filters.srdate.end"
            :config="uConfig"
            class="form-control"
            placeholder="MM-DD-YYYY"
            type="date"
          />
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import SpinnerLoading from "@/components/SpinnerLoading";
import SelectUser from "@/components/SelectUser";

import axiosAF from "@/services/admin/facilities";
import axiosU from "@/services/admin/user";
import { fromConfig, untilConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    SpinnerLoading,
    SelectUser,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stateOptions: [],
      facilityOptions: [],
      omaOptions: [],
      filters: {
        survey_id: this.$route.params.id,
        fid: null,
        pname: null,
        oid: null,
        status: null,
        srdate: {
          start: null,
          end: null
        }
      },
      statusOptions: [
        { name: "Active", value: "true" },
        { name: "Inactive", value: "false" },
      ],
      fConfig: fromConfig(),
      uConfig: untilConfig(),
    };
  },
  mounted() {
    this.getDataFilters();
  },
  methods: {
    searchFilter() {
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      this.$emit("searchFilter", JSON.parse(JSON.stringify(this.filters)));
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
    getDataFilters() {
      axiosAF
        .facilityList(20)
        .then((response) => {
          this.facilityOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      /* --OMA-- */
      const data = {
        role: "OMA",
      };
      axiosU
        .userFilter(20, data)
        .then(({ registro: { data } }) => {
          this.omaOptions = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchOma(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["OMA"],
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
            this.omaOptions = data;
            loading(false);
          });
        }, 400);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
