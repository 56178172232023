<template>
  <div>
    <SurveyFilter
      v-if="$can('filter', 'survey')"
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage"
    />
    <b-card>
      <TitleTable titleTable="Survey" iconTable="FileTextIcon" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="fields"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            class="vs-checkbox-con"
            @change="toggleDetailSurvey(row)"
          >
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <CardResult :id="row.item.id" v-if="isShowResultDetail" />
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>
        <template #cell(is_answered)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.is_answered ? 'Responded' : 'Not Responded'
            }}</span>
          </div>
        </template>
        <!-- <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'View'"
            v-if="$can('show', 'survey')"
            :to="{ name: 'module-survey-results', params: { id: data.item.id } }"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
        </template> -->
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import axiosCV from "@/services/admin/survey/results";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";

import SurveyFilter from "./SurveyFilter.vue";
import CardResult from './CardResult.vue'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BFormCheckbox,
    SurveyFilter,
    CardResult,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      fields: [
        "show_details",
        { key: "patient_name" },
        { key: "facility_name" },
        { key: "oma_name" },
        {
          key: "is_answered",
          label: "Is Answered",
          sortable: true,
        },
        {
          key: "date",
          label: "Created At",
          sortable: true,
        }
      ],
      items: null,
      loading: false,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      surveyFilter: null,
      isShowResultDetail: false
    };
  },
  mounted() {
    this.getSurvey();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getSurvey();
      } else {
        this.searchFilter(this.surveyFilter);
      }
    },
  },
  methods: {
    getSurvey() {
      const id = this.$route.params.id;
      axiosCV
        .surveyResultList(id, this.perPage)
        .then(
          ({ submitions: { data, current_page, total, per_page, ...res } }) => {
            this.items = data.map((item) => {
              item._showDetails = false;
              return item;
            })
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          }
        );
    },
    toggleDetailSurvey(value) {
      value.toggleDetails();
      if(value.detailsShowing){
        this.isShowResultDetail = true
      }else{
        this.isShowResultDetail = false
      }
    },
    pageChanged(page) {
      if (!this.filtro) {
        const id = this.$route.params.id;
        axiosCV
          .surveyResultPagination(id, this.perPage, page)
          .then(({submitions: { data, total, current_page, ...res }}) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosCV
          .surveyResultFilterPagination(this.perPage, page, this.surveyFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true;
        this.surveyFilter = value;
        axiosCV
          .surveyResultFilter(this.perPage, value)
          .then(({ data, total, current_page, ...res }) => {
            this.loading = false;
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.getSurvey();
      }
    },
    filterPage(value) {
      this.filtro = value;
    },
  },
};
</script>

<style></style>
