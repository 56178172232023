<template>
  <b-table :items="items" :fields="fieldsAnswer" class="mb-2"> </b-table>
</template>

<script>
import { BTable } from "bootstrap-vue";
import axiosCV from "@/services/admin/survey/results";
export default {
  components: {
    BTable,
  },
  props:{
    id:{
      type: Number,
      required: true
    }
  },
  mounted() {
    this.getResultDeatil();
  },
  data() {
    return {
      items: [],
      fieldsAnswer: [
        { key: "survey_question", label: "Question" },
        { key: "survey_answer", label: "Answer" },
      ],
    };
  },
  methods: {
    getResultDeatil() {
      axiosCV.surveyResultDetail(this.id).then(({ data: { answer } }) => {
        this.items = answer;
      });
    },
  },
};
</script>

<style></style>
