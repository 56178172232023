import { userAxios } from '@/services'

const surveyResultList = async (id, perPage) => {
    try {
        return await userAxios.get(`survey-admin/submissions/${perPage}/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyResultPagination = async (id, perPage, page) => {
    try {
        return await userAxios.get(`survey-admin/submissions/${perPage}/${id}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyResultFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`survey-admin/submissions/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyResultFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`survey-admin/submissions/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const surveyResultDetail = async (id) => {
    try {
        return await userAxios.get(`survey-admin/answer/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    surveyResultList,
    surveyResultPagination,
    surveyResultFilter,
    surveyResultFilterPagination,
    surveyResultDetail
}